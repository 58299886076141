export const ProductsOffice = [

    {
        id: "Pc Gamer Ags Amd Ryzen5 5600g 16gb Ram 480 Ssd Oferta!!",
        img: "https://http2.mlstatic.com/D_NQ_NP_834373-MLA51930649629_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192459918-pc-gamer-ags-amd-ryzen5-5600g-16gb-ram-480-ssd-oferta-_JM#position=1&search_layout=stack&type=item&tracking_id=dc17e9c4-1ba2-44be-908f-b6d7cf2167e6"
    },
    {
        id: "Pc Gamer Nsx Max Ryzen 5 3600 16gb 500ssd Rx6600xt Oferta!!!",
        img: "https://http2.mlstatic.com/D_NQ_NP_920057-MLA51779571080_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181858436-pc-gamer-nsx-max-ryzen-5-3600-16gb-500ssd-rx6600xt-_JM#position=2&search_layout=stack&type=item&tracking_id=aa29ad47-5e01-47d0-8372-b6ea19bd7be1"
    },
    {
        id: "Pc Gamer Ags R7 5700g 16 Ram 480ssd 12 Cuotas Oferta!!! ",
        img: "https://http2.mlstatic.com/D_NQ_NP_856107-MLA52026697791_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192635430-pc-gamer-ags-r7-5700g-16-ram-480ssd-12-cuotas-oferta-_JM#position=3&search_layout=stack&type=item&tracking_id=a9747992-4790-474a-a308-9af6b48f4518"
    },
    {
        id: "Pc Gamer Ags R5 5600g 16gb 480ssd Hasta 12 Cuotas Oferta!!",
        img: "https://http2.mlstatic.com/D_NQ_NP_834373-MLA51930649629_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192460047-pc-gamer-ags-r5-5600g-16gb-480ssd-hasta-12-cuotas-oferta-_JM#position=4&search_layout=stack&type=item&tracking_id=42c7f412-ebb3-4371-9e02-da2559a8b7b1"
    },
    {
        id: "Pc Nsx Asus Edition I5 10400f 16gb 500gb Ssd Gtx 1660 Ti Evo",
        img: "https://http2.mlstatic.com/D_NQ_NP_995703-MLA51779054706_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181845039-pc-nsx-asus-edition-i5-10400f-16gb-500gb-ssd-gtx-1660-ti-evo-_JM#position=5&search_layout=stack&type=item&tracking_id=08d50fa4-f480-4650-8600-ebf16cc352c9"
    },
    {
        id: "Pc Nsx Gaming Pro R5 5600g 16gb 500 Ssd Sata 2.5",
        img: "https://http2.mlstatic.com/D_NQ_NP_747273-MLA51777146542_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181192951-pc-nsx-gaming-pro-r5-5600g-16gb-500-ssd-sata-25-_JM#position=6&search_layout=stack&type=item&tracking_id=79c87251-3a22-49a7-8ee9-24ad44afdcea"
    },
    {
        id: "Pc Gamer Ags Amd Ryzen 7 5700g 16gb Ram 480ssd Oferta!!!",
        img: "https://http2.mlstatic.com/D_NQ_NP_985866-MLA52026865030_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192699715-pc-gamer-ags-amd-ryzen-7-5700g-16gb-ram-480ssd-oferta-_JM#position=7&search_layout=stack&type=item&tracking_id=0e3ad4bb-d2e0-4f03-93f8-9a74c0bf296a"
    },
    {
        id: "Pc Gamer Ags R5 5600g 16gb Ram 480ssd 24 Cuotas Oferta!!",
        img: "https://http2.mlstatic.com/D_NQ_NP_881456-MLA52006121860_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199794401-pc-gamer-ags-r5-5600g-16gb-ram-480ssd-24-cuotas-oferta-_JM#position=8&search_layout=stack&type=item&tracking_id=1181c15c-5abc-46f1-b0c9-dd22e82fbca4"
    },
    {
        id: "Pc Nsx Asus Edition I3 10100f 16gb 500gb Gtx 1650 Phoenix Oferta!!",
        img: "https://http2.mlstatic.com/D_NQ_NP_718177-MLA51778971852_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181890372-pc-nsx-asus-edition-i3-10100f-16gb-500gb-gtx-1650-phoenix-_JM#position=9&search_layout=stack&type=item&tracking_id=2389aea2-44c3-4d80-87ff-150fbdcd140f"
    },
    {
        id: "Pc Gamer Ags R7 5700g 16gb Ram 480ssd 24 Cuotas Oferta!!! ",
        img: "https://http2.mlstatic.com/D_NQ_NP_628254-MLA52026680893_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1198724699-pc-gamer-ags-r7-5700g-16gb-ram-480ssd-24-cuotas-oferta-_JM#position=10&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Nsx Gaming Pro 4700s 16gb Gddr6 512ssd Sata 2.5 Rx550 2gb",
        img: "https://http2.mlstatic.com/D_NQ_NP_628254-MLA52026680893_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181846203-pc-nsx-gaming-pro-4700s-16gb-gddr6-512ssd-sata-25-rx550-2gb-_JM#position=11&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Nsx Gaming Max I5 11400f 16gb Ram 500ssd Rx 6600 Oferta!!! ",
        img: "https://http2.mlstatic.com/D_NQ_NP_709086-MLA51779314082_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181832310-pc-nsx-gaming-max-i5-11400f-16gb-ram-500ssd-rx-6600-_JM#position=14&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx Amd R7 5800x 32gb Ram 1tb Ssd Rtx 3080 Ti Rog",
        img: "https://http2.mlstatic.com/D_NQ_NP_951795-MLA52049966071_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199940410-pc-gamer-nsx-amd-r7-5800x-32gb-ram-1tb-ssd-rtx-3080-ti-rog-_JM#position=16&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Corsair R7 5700g 16gb 1tb Rtx 3080 Ti Rog ",
        img: "https://http2.mlstatic.com/D_NQ_NP_948996-MLA52049764765_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181872084-pc-nsx-desktop-4700s-16gb-ssd256gb-rx550-2gb-_JM#position=17&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Cooler Master I5 12400f 16 1tb Rtx 3060 Ti V",
        img: "https://http2.mlstatic.com/D_NQ_NP_635846-MLA52049787208_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199790869-pc-gamer-nsx-by-cooler-master-i5-12400f-16-1tb-rtx-3060-ti-v-_JM#position=20&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Corsair R5 5600x 16gb 1tb Rtx 3080 Ti Rog",
        img: "https://http2.mlstatic.com/D_NQ_NP_853952-MLA52049911084_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1200252133-pc-gamer-nsx-by-corsair-r5-5600x-16gb-1tb-rtx-3080-ti-rog-_JM#position=21&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Cooler Master I5 12400f 16 1tb Rtx 3060 Ti V",
        img: "https://http2.mlstatic.com/D_NQ_NP_887279-MLA52049211954_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199130417-pc-gamer-nsx-by-cooler-master-i5-12400f-16-1tb-rtx-3060-ti-v-_JM#position=24&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Corsair R7 5700g 16gb 1tb Rtx 3080 Ti Rog ",
        img: "https://http2.mlstatic.com/D_NQ_NP_948996-MLA52049764765_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199805247-pc-gamer-nsx-by-corsair-r7-5700g-16gb-1tb-rtx-3080-ti-rog-_JM#position=18&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    }
    
    
    




















   /*  {
        id:"PCI38GB250-1",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc20_1.jpg',
        Model: "Model SC20",
        sku:"PCI38GB250-1",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"ASROCK",
        MotherBoardModel:"H510M-HDV",
        ProcessorBrand : "Intel",
        Processor : "Core i3 10100" ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 8GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
    {
        id:"PCI58GB250-1",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc30_1.jpg',
        Model: "Model SC30",
        sku:"PCI58GB250-1",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"ASROCK",
        MotherBoardModel:"H510M-HDV",
        ProcessorBrand : "Intel",
        Processor : "Core i5 10400" ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 8GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
    {
        id:"PCI38GB250-2",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc20_2.jpg',
        Model: "Model SC20",
        sku:"PCI38GB250-2",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"ASROCK",
        MotherBoardModel:"H510M-HDV",
        ProcessorBrand : "Intel",
        Processor : "Core i3 10100" ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 16GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
    {
        id:"PCI58GB250-2",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc30_2.jpg',
        Model: "Model SC30",
        sku:"PCI58GB250-2",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"ASROCK",
        MotherBoardModel:"H510M-HDV",
        ProcessorBrand : "Intel",
        Processor : "Core i5 10400" ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 16GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
    {
        id:"PCR58GB250-1",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc45_1.jpg',
        Model: "Model SC45",
        sku:"PCR58GB250-1",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"PRO A520M-A PRO",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 5 4600G " ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 8GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
    {
        id:"PCR58GB250-2",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc45_2.jpg',
        Model: "Model SC45",
        sku:"PCR58GB250-2",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"PRO A520M-A PRO",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 5 4600G " ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 16GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
    {
        id:"PCR532GB512-2",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/PCR532GB512-2.jpg',
        Model: "Model SC45",
        sku:"PCR532GB512-2",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"PRO A520M-A PRO",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 5 4600G " ,
        StorageMain:"512GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 32GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
    {
        id:"PCR58GB250-4",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc45_4.jpg',
        Model: "Model SC45",
        sku:"PCR58GB250-4",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"PRO A520M-A PRO",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 5 4600G " ,
        StorageMain:"512GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 16GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    }, */
    
]