export const Products = [

    {
        category: "monitor",
        id: "Monitor Gamer Samsung F24t35 Led 24  Azul Y Gris 100v/240v",
        img: "https://http2.mlstatic.com/D_NQ_NP_918060-MLA52028333672_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201726748-monitor-gamer-samsung-f24t35-led-24-azul-y-gris-100v240v-_JM"
    },
    {
        category: "monitor",
        id: "Monitor Curvo 34 Samsung A650 Ultrawide Wqhd Hd 100hz 5ms ",
        img: "https://http2.mlstatic.com/D_NQ_NP_907083-MLA52029626772_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202206124-monitor-curvo-34-samsung-a650-ultrawide-wqhd-hd-100hz-5ms-_JM"
    },
    {
        category: "monitor",
        id: "Monitor Samsung Odyssey Crg5 24'' Curvo oferta!!! ",
        img: "https://http2.mlstatic.com/D_NQ_NP_851538-MLA52028187083_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201726442-monitor-samsung-odyssey-crg5-24-curvo-_JM"
    },
    {
        category: "monitor",
        id: "Monitor Samsung 27  Series S70a 4k Ips  Hdr 60 Hz",
        img: "https://http2.mlstatic.com/D_NQ_NP_680385-MLA52029524718_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202206029-monitor-samsung-27-series-s70a-4k-ips-hdr-60-hz-_JM"
    },
    {
        category: "monitor",
        id: "Monitor Samsung Flat 22  Con Hdmi/vga",
        img: "https://http2.mlstatic.com/D_NQ_NP_715310-MLA52028865628_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201900920-monitor-samsung-flat-22-con-hdmivga-_JM"
    },
    {
        category: "monitor",
        id: "Monitor Gamer Samsung S19a330 Lcd 19   Negro 100v/240v",
        img: "https://http2.mlstatic.com/D_NQ_NP_942586-MLA52028826081_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201727254-monitor-gamer-samsung-s19a330-lcd-19-negro-100v240v-_JM"
    },
    {
        category: "silla",
        id: "Silla Gamer Corsair T3 Rush Grey/white",
        img: "https://http2.mlstatic.com/D_NQ_NP_786366-MLA52028766521_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202204815-silla-gamer-corsair-t3-rush-greywhite-_JM"
    },
    {
        category: "silla",
        id: "Silla Gamer Corsair T2 Road Negra Y Blanco (cf-9010007-w)",
        img: "https://http2.mlstatic.com/D_NQ_NP_724021-MLA52027646111_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202321030-silla-gamer-corsair-t2-road-negra-y-blanco-cf-9010007-w-_JM"
    },
    {
        category: "silla",
        id: "Silla Gamer Msi Mag Series Ch130x ",
        img: "https://http2.mlstatic.com/D_NQ_NP_786437-MLA52109094381_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201900413-silla-gamer-msi-mag-series-ch130x-_JM"
    },
    {
        category: "silla",
        id: "Silla Gamer Corsair T3 Rush Grey/charcoal",
        img: "https://http2.mlstatic.com/D_NQ_NP_764264-MLA52028776867_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202307340-silla-gamer-corsair-t3-rush-greycharcoal-_JM"
    },
    {
        category: "silla",
        id: "Silla Gamer Corsair T1 Race Negra Y Azul (cf-9010014-w)",
        img: "https://http2.mlstatic.com/D_NQ_NP_675788-MLA46237501182_062021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202346713-silla-gamer-corsair-t1-race-negra-y-azul-cf-9010014-w-_JM"
    },
    {
        category: "silla",
        id: "Silla Gamer Corsair T2 Road Negra Y Amarillo (cf-9010010-w)",
        img: "https://http2.mlstatic.com/D_NQ_NP_627524-MLA46331196281_062021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201803119-silla-gamer-corsair-t2-road-negra-y-amarillo-cf-9010010-w-_JM"
    },
    {
        category: "mouse",
        id: "Mouse Patriot Gamer V551 Rgb Optical Usb",
        img: "https://http2.mlstatic.com/D_NQ_NP_749235-MLA47516796350_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103532528-mouse-patriot-gamer-v551-rgb-optical-usb-_JM"
    },
    {
        category: "mouse",
        id: "Mouse Patriot Vipper Gamig V550 Rgb Optical Usb",
        img: "https://http2.mlstatic.com/D_NQ_NP_884067-MLA44093897825_112020-O.webp",
        url: "https://www.nsx.com.ar/MLA-897459381-mouse-patriot-vipper-gamig-v550-rgb-optical-usb-_JM"
    },
    {
        category: "mouse",
        id: "Mouse Gamer Qbox Qbm962 Cable 2 Botones By Nsx",
        img: "https://http2.mlstatic.com/D_NQ_NP_774405-MLA51841116029_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186225444-mouse-gamer-qbox-qbm962-cable-2-botones-by-nsx-_JM"
    },
    {
        category: "mouse",
        id: "Mouse Gamer Qbox M942 Optico 6400dpi",
        img: "https://http2.mlstatic.com/D_NQ_NP_827317-MLA51904900330_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1190263860-mouse-gamer-qbox-m942-optico-6400dpi-_JM"
    },
    {
        category: "mouse",
        id: "Mouse Gamer Qbox Qbm993 Rgb Con Cable",
        img: "https://http2.mlstatic.com/D_NQ_NP_906710-MLA51905043479_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1190123055-mouse-gamer-qbox-qbm993-rgb-con-cable-_JM"
    },
    {
        category: "teclado",
        id: "Teclado Gamer Patriot Viper V765 Rgb Kailh White Switch Pro",
        img: "https://http2.mlstatic.com/D_NQ_NP_616340-MLA44094304236_112020-O.webp",
        url: "https://www.nsx.com.ar/MLA-897467858-teclado-gamer-patriot-viper-v765-rgb-kailh-white-switch-pro-_JM"
    },
    {
        category: "teclado",
        id: "Teclado Gaming Viper V730 Led Red Mechanical Pv730mbulgm",
        img: "https://http2.mlstatic.com/D_NQ_NP_911035-MLA47517193363_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103489508-teclado-gaming-viper-v730-led-red-mechanical-pv730mbulgm-_JM"
    },
    {
        category: "teclado",
        id: "Teclado Gamer Qbox K9260 Rgb Rainbow Antighost Semi-mecanico",
        img: "https://http2.mlstatic.com/D_NQ_NP_999122-MLA51905460890_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1190168699-teclado-gamer-qbox-k9260-rgb-rainbow-antighost-semi-mecanico-_JM"
    },
    {
        category: "teclado",
        id: "Teclado Mecanico Gamer Patriot Viper V770 Rgb Aluminio Kailh",
        img: "https://http2.mlstatic.com/D_NQ_NP_832620-MLA47517320567_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103496349-teclado-mecanico-gamer-patriot-viper-v770-rgb-aluminio-kailh-_JM"
    },
    {
        category: "teclado",
        id: "Teclado Gamer Qbox Qbmk07 Cable Luz Rgb Configurable Español",
        img: "https://http2.mlstatic.com/D_NQ_NP_718622-MLA51905435235_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1190168509-teclado-gamer-qbox-qbmk07-cable-luz-rgb-configurable-espanol-_JM"
    },
    {
        category: "auricular",
        id: "Auricular Qbox Oficina H004 Notebook Pc By Nsx",
        img: "https://www.simm.com.ar/Temp/App_WebSite/App_PictureFiles/Items/QBHS002_800.jpg",
        url: "https://www.nsx.com.ar/MLA-1186149090-auricular-qbox-oficina-h004-notebook-pc-by-nsx-_JM"
    },
    {
        category: "auricular",
        id: "Auricular Gamer Patriot Viper V330 Stereo Gaming Headset",
        img: "https://http2.mlstatic.com/D_NQ_NP_639155-MLA48036565544_102021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103494461-auricular-gamer-patriot-viper-v330-stereo-gaming-headset-_JM"
    },
    {
        category: "auricular",
        id: "Auricular Gamer Patriot Viper V360 7.1 Con Sonido Envolvente",
        img: "https://www.qloud.ar/SITES/IMG/m-y-m-computacion-06-2020/0000000000000815530019893.jpg",
        url: "https://www.nsx.com.ar/MLA-1103494662-auricular-gamer-patriot-viper-v360-71-con-sonido-envolvente-_JM"
    },
    {
        category: "auricular",
        id: "Auricular Oficina Qbox H003 Con Micrófono By Nsx",
        img: "https://http2.mlstatic.com/D_NQ_NP_738162-MLA51840522398_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186118973-auricular-oficina-qbox-h003-con-microfono-by-nsx-_JM"
    },
    {
        category: "auricular",
        id: "Auriculares Gamer Qbox H039 Cable Mallado Usb By Nsx",
        img: "https://front.dev.malditohard.com.ar/img/migration/AURICULAR-GAMER-QBOX-H039.webp",
        url: "https://www.nsx.com.ar/MLA-1186099822-auriculares-gamer-qbox-h039-cable-mallado-usb-by-nsx-_JM"
    },
    {
        category: "auricular",
        id: "Auricular Gamer Patriot Viper V380 7.1 Rgb Esports Pc Gaming",
        img: "https://http2.mlstatic.com/D_NQ_NP_690552-MLA44339235891_122020-O.webp",
        url: "https://www.nsx.com.ar/MLA-901134664-auricular-gamer-patriot-viper-v380-71-rgb-esports-pc-gaming-_JM"
    },
    {
        category: "disco",
        id: "Disco Solido Patriot P210 Hd Ssd 1tb Sata3 2.5",
        img: "https://http2.mlstatic.com/D_NQ_NP_941366-MLA51863619640_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1132010407-disco-solido-patriot-p210-hd-ssd-1tb-sata3-25-_JM"
    },
    {
        category: "disco",
        id: "Disco Sólido Interno Ssd Patriot 256gb P210 ",
        img: "https://http2.mlstatic.com/D_NQ_NP_902360-MLA47512853629_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103413398-disco-solido-interno-ssd-patriot-256gb-p210-_JM"
    },
    {
        category: "disco",
        id: "Disco Ssd Estado Solido 128gb 2,5 Patriot P210",
        img: "https://http2.mlstatic.com/D_NQ_NP_618307-MLA49655014676_042022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1132015300-disco-ssd-estado-solido-128gb-25-patriot-p210-_JM"
    },
    {
        category: "disco",
        id: "Ssd Disco Solido 1tb Patriot P210 Sata 3 Gamer Pc Notebook",
        img: "https://http2.mlstatic.com/D_NQ_NP_990626-MLA50233717376_062022-O.webp",
        url: "https://www.nsx.com.ar/ssd-disco-solido-1tb-patriot-p210-sata-3-gamer-pc-notebook/p/MLA19722952"
    },
    {
        category: "disco",
        id: "Disco Solido Ssd Patriot P210 256gb Sata 500mb/s Pc Notebook",
        img: "https://http2.mlstatic.com/D_NQ_NP_902360-MLA47512853629_092021-O.webp",
        url: "https://www.nsx.com.ar/disco-solido-ssd-patriot-p210-256gb-sata-500mbs-pc-notebook/p/MLA19722948"
    },
    {
        category: "disco",
        id: "Disco sólido interno Patriot P210S512G25 512GB",
        img: "https://http2.mlstatic.com/D_NQ_NP_870567-MLA49705854798_042022-O.webp",
        url: "https://www.nsx.com.ar/disco-solido-interno-patriot-p210s512g25-512gb/p/MLA19050940"
    },
    {
        category: "disco",
        id: "Disco Ssd Interno Patriot P210 Solid 512gb Sata3",
        img: "https://http2.mlstatic.com/D_NQ_NP_847142-MLA47513250292_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103451293-disco-ssd-interno-patriot-p210-solid-512gb-sata3-_JM"
    },
    {
        category: "disco",
        id: "Disco Solido Ssd M2 Nvme 1tb Patriot P300 P300p1tbm28",
        img: "https://http2.mlstatic.com/D_NQ_NP_802459-MLA52417408604_112022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1240823327-disco-solido-ssd-m2-nvme-1tb-patriot-p300-p300p1tbm28-_JM"
    },
    {
        category: "disco",
        id: "Disco Ssd Patriot P400 1tb M2 Pci-e Gen4",
        img: "https://http2.mlstatic.com/D_NQ_NP_878614-MLA49658037729_042022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1132031451-disco-ssd-patriot-p400-1tb-m2-pci-e-gen4-_JM"
    }
    
]