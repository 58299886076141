export const Notebooks = [
    {
        id:"EpsilonCloud",
        Model: "NSX EPSILON CLOUD",
        Descripcion: "La notebook EPSILON CLOUD fue diseñada para acompañarte todos los días. Posee una pantalla Full HD de 14” pulgadas que te permitirá disfrutar de una calidad de color e imagen. Su procesador Intel® Celeron™ ofrecen el máximo rendimiento con el menor consumo de energía. A su vez permite incorporar un SSD para aumentar su velocidad y capacidad de almacenamiento." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/cloud2.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel® Celeron™",
        Processor : "N3350 Dual Core 2.4GHz" ,
        Supply: "5000MAH 7.4V",
        Screen: '14" Full HD LCD',
        Dimensiones: "328 x 217 x 17 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "4GB LP-DDR4",
        Warranty: "1 año",
        StorageMain:"64GB + Slot libre para SSD",
        Usb:"3.0 x1, 2.0 x2",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N, BT4.0",
        url: "https://www.nsx.com.ar/MLA-1181831476-notebook-nsx-epsilon-cloud-4gb-ram-64ssd-freedos-_JM#position=5&search_layout=stack&type=item&tracking_id=ae082ac0-b62b-4d16-964a-02273f396029"
    },
    {
        id:"argusi3",
        Model: "NSX ARGUS i3",
        Descripcion: "La tecnología que necesitas para trabajar desde tu casa y también en la oficina. Con la Notebook ARGUS I3 podrás potenciar tu rendimiento al máximo. Este producto tiene un procesador Intel® Core I3 y 4 GB de Memoria RAM que permite que puedas trabajar en movimiento, sin perder rendimiento, además cuenta con un SSD que abre archivos pesados a gran velocidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/argusi3.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "2MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i3-10110U Dual Core 2.10 GHz Turbo 4.10 GHz" ,
        Supply: "4500MAH 11.4V",
        Screen: '15.6" FHD LCD',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "4GB + Slot disponible",
        Warranty: "1 año",
        StorageMain:"SSD 480GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N, BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184380636-notebook-nsx-argus-i3-10110u-4gb-480ssd-156-fhd-windows-11-_JM#position=4&search_layout=stack&type=item&tracking_id=3d01969b-335e-44b1-a267-e08ba77d659f"
    },
    {
        id:"argusi5",
        Model: "NSX ARGUS i5",
        Descripcion: "La tecnología que necesitas para trabajar desde tu casa y también en la oficina. Con la Notebook ARGUS I3 podrás potenciar tu rendimiento al máximo. Este producto tiene un procesador Intel® Core I3 y 4 GB de Memoria RAM que permite que puedas trabajar en movimiento, sin perder rendimiento, además cuenta con un SSD que abre archivos pesados a gran velocidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/argusi5.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "2MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-10210U Quad Core 1.60GHz Turbo 4.20GHz" ,
        Supply: "4500MAH 11.4V",
        Screen: '15.6" FHD LCD',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "8GB + Slot disponible",
        Warranty: "1 año",
        StorageMain:"SSD 480GB M2 NVME",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N, BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184440056-notebook-nsx-argus-i5-10210u-8gb-ram-480-ssd-free-dos-_JM#position=2&search_layout=stack&type=item&tracking_id=5d9aa4ed-2c07-4512-a592-01f13adf6e04"
    },
    {
        id:"alkoni5",
        Model: "NSX ALKON i5",
        Descripcion: "Redefine tu forma de trabajar. Con la Notebook ALKON I5 podrás potenciar tu rendimiento al máximo. Este portatil tiene un procesador Intel® Core I5 y 8 GB de Memoria RAM más que suficiente para llegar a la efectividad que necesitas, además cuenta con un SSD que permite aumentar tu capacdad de almacenamiento y tu velocidad de carga." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/alkoni5.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "1MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1035G1 Caché de 6M Turbo 3,60GHz" ,
        Supply: "4000MAH 11.4V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "8GB DDR4-2666 + Slot disponible hasta 32GB",
        Warranty: "1 año",
        StorageMain:"SSD 240GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N 2.4GHZ + 5.0 GHZ / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1181833848-notebook-nsx-alkon-14-fhd-i5-1035g1-8gb-ssd-240-w11home-_JM#position=3&search_layout=stack&type=item&tracking_id=bf81cf99-9e00-45ec-ab5b-726ebd321e0c"
    },
    {
        id:"alkoni7",
        Model: "NSX ALKON i7",
        Descripcion: "Redefine tu forma de trabajar. Con la Notebook ALKON I7 podrás potenciar tu rendimiento al máximo. Este portatil tiene un procesador Intel® Core I7 y 8 GB de Memoria RAM más que suficiente para llegar a la efectividad que necesitas, además cuenta con un SSD que permite aumentar tu capacdad de almacenamiento y tu velocidad de carga." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/alkoni7.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "1MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i7-1065G1 Caché de 6M Turbo 3,60GHz" ,
        Supply: "4000MAH 11.4V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "8GB DDR4-2666 + Slot disponible hasta 32GB",
        Warranty: "1 año",
        StorageMain:"SSD 240GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N 2.4GHZ + 5.0 GHZ / BT4.0",
        url:"https://www.nsx.com.ar/MLA-1184053713-notebook-nsx-alkon-i7-1065g1-8gb-240-ssd-14-windows-11-home-_JM#position=2&search_layout=stack&type=item&tracking_id=bf9a591f-3d8a-4581-a518-f47467a5fae6"
    },
    /* {
        id:"epsilon",
        Model: "NSX EPSILON",
        Descripcion: "La notebook EPSILON cuenta con un diseño compacto y funcional. Posee una pantalla Full HD de 14” pulgadas que te permitirá disfrutar de una calidad de color e imagen. Su procesador Intel® Dual Core junto con su GPU ofrecen el máximo rendimiento con el menor consumo de energía. A su vez que permite ejecutar cualquier tarea con facilidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/epsilon.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Celeron N3350 2.4 GHz" ,
        Supply: "4500MAH 7.6V",
        Screen: '14.1" FULL HD LED',
        Dimensiones: "330 x 221 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "4GB LP-DDR3",
        Warranty: "1 año",
        StorageMain:"SSD 64GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802 11N BT4.0",
        url: "https://www.nsx.com.ar/notebook-nsx-epsilon-nccf5s64w-negra-141-intel-celeron-n3350-4gb-de-ram-64gb-ssd-intel-hd-graphics-1920x1080px-windows-11-home/p/MLA19686857?pdp_filters=seller_id:293425181#position=1&search_layout=stack&type=item&tracking_id=b17c7f51-327b-4b7b-bd61-0c881446f4be"
    }, */
    // {
    //     id:"omicron",
    //     Model: "NSX OMICRON",
    //     Descripcion: "La tecnología que necesitas para trabajar desde tu casa y también en la oficina. Con la Notebook OMICRON podrás potenciar tu rendimiento al máximo. Este producto tiene un procesador Intel® Core I3 y 12 GB de Memoria RAM que permite que puedas trabajar en movimiento, sin perder rendimiento, además abre archivos pesados a gran velocidad." ,
    //     img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/omicron.png',
    //     OperatingSystem:"Windows",
    //     OperatingSystemVersion:"10 PRO",
    //     Camera: "0.3MP VGA",    
    //     ProcessorBrand : "Intel®",
    //     Processor : "Core i3-1005G1" ,
    //     Supply: "4500MAH 7.6V",
    //     Screen: '14" FULL HD LCD',
    //     Dimensiones: "320 x 270 x 18 mm",
    //     Peso:"1.5kg",
    //     Teclado:"Español",
    //     Ram: "12gb",
    //     Warranty: "1 año",
    //     StorageMain:"SSD 480GB SATA 2.5'",
    //     Usb:"x2",
    //     MiniHdmi: "x1",
    //     MicroSdCard: "x1",
    //     Rj45: "no",
    //     Audio:"x1 (3.5mm) Mic Incorporado",
    //     Conectividad: "AC WIFI / BT4.0",
    // },
    /* {
        id:"sigma",
        Model: "NSX SIGMA",
        Descripcion: "Con la Notebook SIGMA podrás potenciar tu rendimiento al máximo. Gracias a los procesadores Intel® Core i5 de Décima Generación podrás aumentar la rapidez del equipo en las tareas cotidianas, realizando tus tareas más exigentes y trabajando en movimiento, sin perder rendimiento. Podrás abrir tus aplicaciones, archivos de gran tamaño y cargar los niveles de tus juegos favoritos a gran velocidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/sigma.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"10 PRO",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1035G1" ,
        Supply: "4500MAH 7.6V",
        Screen: '14" FULL HD LCD',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "12gb",
        Warranty: "1 año",
        StorageMain:"SSD 480GB SATA 2.5'",
        Usb:"x2",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184439805-notebook-nsx-sigma-i5-1035g4-12gb-ram-480-ssd-windows-10-pro-_JM#position=5&search_layout=stack&type=item&tracking_id=9db929c3-d08e-4002-93af-b69f1f79bb30"
    },
    {
        id:"omega",
        Model: "NSX OMEGA",
        Descripcion: "Con la notebook OMEGA no has de sacrificar rendimiento para tener un equipo pequeño: está diseñado para que hagas lo que quieras en el mínimo tiempo posible y sin complicarte la vida. Los procesadores Intel® Core I7 ofrecen una capacidad de respuesta superior, mientras que asegura el rendimiento necesario para acometer tareas exigentes de forma eficiente." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/omega.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"10 PRO",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1035G1" ,
        Supply: "5000MAH 7.6V",
        Screen: '14" FULL HD LCD',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "12gb",
        Warranty: "1 año",
        StorageMain:"SSD 500GB SATA 2.5'",
        Usb:"x2",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184446724-notebook-nsx-omega-i7-12gb-500ssd-windows-10-pro-_JM#position=5&search_layout=stack&type=item&tracking_id=f91cae35-68f1-45ee-a569-56aee119bb1b"
    }, */
    {
        id:"Kairosi5",
        Model: "NSX KAIROS I5 ULTRASLIM",
        Descripcion: "La notebook KAIRÓS i5 cuenta con un diseño compacto y funcional. Posee los nuevos procesadores Intel® CoreTM i5 de 10a generación, que junto a SSD puedes experimentar una mejora monumental en las velocidades de lectura / escritura, ideal para tareas que requieren multiples procesos. Con un chasis sólido y liviano, su acabado metálico de primera calidad llama la atención y se destaca entre la multitud." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/kairosi5ultraslim.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"10 PRO",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1021OU" ,
        Supply: "3600MAH 11.44V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "16gb",
        Warranty: "1 año",
        StorageMain:"SSD 250 hasta 1TB",
        Usb:"x2",
        MiniHdmi: "no",
        MicroSdCard: "no",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184440635-notebook-nsx-kairos-i5-10210u-16gb-250-ssd-14-windows-10-pro-_JM#position=5&search_layout=stack&type=item&tracking_id=2fe2d17e-9e7f-42ec-84d4-4ac3c3d29b5d"
    },
    {
        id:"Kairosi7",
        Model: "NSX KAIROS I7 ULTRASLIM",
        Descripcion: "Un portátil que incorporan los procesadores Intel® CoreTM i7 de 10a generación, los más modernos, hasta con 16 GB de RAM, lo que resulta ideal para todas las tareas informáticas y de entretenimiento diarias. Con respecto al almacenamiento, tiene un disco de estado sólido (SSD) para obtener velocidades de acceso a los datos más rápidas y mejorar enormemente los tiempos de arranque, además de la protección de los datos" ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/kairosi7ultraslim.png',
        OperatingSystem:"FREEDOS",
        OperatingSystemVersion:"FreeDOS",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i7-10510U" ,
        Supply: "3600MAH 11.44V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "16gb",
        Warranty: "1 año",
        StorageMain:"SSD 500 hasta 1TB",
        Usb:"x2",
        MiniHdmi: "no",
        MicroSdCard: "no",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184408389-notebook-nsx-kairos-i7-105100u-16gb-500-ssd-14-freedos-_JM#position=1&search_layout=stack&type=item&tracking_id=512b2f07-128e-4799-8029-bb0ba719f588"
    },
    ]