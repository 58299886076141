export const Products = [
    {
        id:"PGI5VNS4W-01",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-01.jpg',
        Model: "Model SG60",
        sku:"PGI5VNS4W-01",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B560M-A PRO WIFI",
        ProcessorBrand : "Intel",
        Processor : "Core i5 10400" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 2060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"EVGA",
        Supply:"600W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-02",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-02.jpg',
        Model: "Model SG70",
        sku:"PGI5VNS4W-02",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B660 PRO WIFI",
        ProcessorBrand : "Intel",
        Processor : "Core i5 12400F" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGR7VNS4W-01",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-01.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-01",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"NVIDIA EVGA",
        GraphicModel:"RTX 3070 TI FTW3 ULTRA",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PGI5VNS4W-03",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-03.jpg',
        Model: "Model SG60",
        sku:"PGI5VNS4W-03",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B560M-A PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 10400" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 2060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"EVGA",
        Supply:"600W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-05",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-05.jpg',
        Model: "Model SG70",
        sku:"PGI5VNS4W-05",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MSI B660 PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 12400F" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-04",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-04.jpg',
        Model: "Model SG60",
        sku:"PGI5VNS4W-04",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B560M-A PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 10400" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 2060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"EVGA",
        Supply:"600W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-06",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-06.jpg',
        Model: "Model SG70",
        sku:"PGI5VNS4W-06",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B660 PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 12400F" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGR7VNS4W-02",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-02.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-02",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3070 TI FTW3 ULTRA",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PGR7VNS4W-03",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-03.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-03",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 32GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"MSI",
        GraphicModel:"RTX 3070 VENTUS 2X OC",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PGR7VNS4W-04",
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-04.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-04",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"MSI",
        GraphicModel:"RTX 3070 VENTUS 2X OC",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PCR58GB250-1",
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc45_1.jpg',
        Model: "Model SC45",
        sku:"PCR58GB250-1",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"PRO A520M-A PRO",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 5 4600G " ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 8GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },
]