/* import axios from "axios"; */
/* import { useState } from "react"; */


export const Success = () => {
  /* const [url, setUrl] = useState([]); */

    /* const direccion = `http://localhost:4112/MP/success` */
  /*   const direccion = `http://localhost:8080/feedback?collection_id=1308953609&collection_status=approved&payment_id=1308953609&status=approved&external_reference=null&payment_type=credit_card&merchant_order_id=6141004974&preference_id=340365869-172c92d3-8703-4f48-8b0a-9b8fcc642d0b&site_id=MLA&processing_mode=aggregator&merchant_account_id=null`


            fetch(direccion)
            .then((response) => response.json())
            .then((data) => {
              setUrl(data)
            })
            .catch(function(error) {
                console.log(error)
            })


            console.log(url);
  
      let handleClick = (e) => {

      } */


  return (
    <div>
        <form method="post">
            <button type='submit' >checkout</button>
        </form>
    </div>
  )
}
